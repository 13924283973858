<template>
  <div style="width: 100%">
    <div style="width: 100%" class="pt-2 d-flex">
      <p class="label_default ">Layout cho option: </p>
      <v-radio-group v-model="optionLayoutType" row class="pt-0 mt-0 ml-2">
        <template v-for="(option, i) in question_layout_types">
          <v-radio :key="i" :label="option.label" :value="option.value"></v-radio>
        </template>
      </v-radio-group>
    </div>

    <div style="width: 100%" class="pt-4 d-flex">
      <p class="label_default ">Option element: </p>
      <v-radio-group v-model="optionContentType" row class="pt-0 mt-0 ml-2">
        <template v-for="(content_type, i) in question_content_types">
          <v-radio :key="i" :label="content_type.label" :value="content_type.value"></v-radio>
        </template>
      </v-radio-group>
    </div>

    <div style="width: 100%" class="pt-4">
      <p class="label_default ">Options: </p>
      <template v-if="optionContentType === this.question_content_type_constant.TEXT">
        <template v-for="(option, i) in dataOption">
          <div :key="i" style="width: 70%" class="d-flex">
            <v-text-field
                outlined dense
                class="mt-0"
                :label="'Option' + (i + 1)"
                v-model="option.text"
            ></v-text-field>
            <v-icon class="pb-5" dense large @click="deleteOption(i)">mdi-delete</v-icon>
          </div>
        </template>
      </template>

      <template v-if="
          [
            this.question_content_type_constant.IMAGE,
            this.question_content_type_constant.TEXT_AND_IMAGE,
          ].includes(optionContentType)
        ">
        <template v-for="(option, i) in dataOption">
          <div :key="option.offset" style="width: 100%">
            <div style="width: 100%" class="d-flex">
              <v-file-input
                  outlined dense
                  class="mt-0"
                  prepend-icon="mdi-image"
                  accept="image/png, image/jpeg, image/jpg"
                  :label="'Chọn ảnh cho option ' + (i + 1)"
                  @change="(e) => onImageFileOptionChange(e, i)"
                  :value="option.image_file"
              ></v-file-input>
              <v-icon class="pb-5" dense large @click="deleteOption(i)">mdi-delete</v-icon>
            </div>
            <v-img
                v-if="option.image_data"
                :src="option.image_data"
                max-width="100%" max-height="200" class="mb-2"
                contain
            />
            <v-text-field
                class="ml-8"
                v-if="option.image_data"
                outlined dense
                placeholder="Nhập mô tả ảnh ..."
                :label="'Mô tả ảnh ' + (i + 1)"
                v-model="option.text"
            />
          </div>
        </template>
      </template>

      <template v-if="optionContentType === this.question_content_type_constant.AUDIO">
        <template v-for="(option, i) in dataOption">
          <div :key="option.offset" style="width: 100%">
            <div style="width: 100%" class="d-flex">
              <v-file-input
                  outlined dense
                  class="mt-0"
                  prepend-icon="mdi-music-note"
                  accept="audio/*"
                  :label="'Chọn audio cho option ' + (i + 1)"
                  @change="(e) => onAudioFileOptionChange(e, i)"
                  :value="option.audio_file"
              ></v-file-input>
              <v-icon class="pb-5" dense large @click="deleteOption(i)">mdi-delete</v-icon>
            </div>
            <vuetify-audio
                v-if="option.audio_data"
                :file="option.audio_data"
                color="primary" class="mb-6"
            />
          </div>
        </template>
      </template>

      <div style="width: 180px" class="d-flex cursor-pointer" @click="addOption()">
        <v-btn outlined icon color="sliver">
          <v-icon color="sliver">mdi-plus</v-icon>
        </v-btn>
        <span class="text-h6 mt-1 ml-1">Thêm option</span>
      </div>
    </div>

    <div style="width: 100%" class="mt-8 d-flex">
      <p class="label_default font-weight-bold">Chọn đáp án: </p>
      <v-select
        style="max-width: 150px"
        v-model="correctOptionIndex"
        :items="dataOption.map((option, index) => {
          return {label: 'Option ' + (index + 1), value: index}
        })"
        item-text="label"
        item-value="value"
        outlined dense
        class="mt-0 ml-2"
      />
    </div>
  </div>
</template>

<script>
import constantTestBuilder from "../../mixins/Support/constantTestBuilder";
import VuetifyAudio from "../../../marking-management/Audio";

export default {
  name: "SingleChoiceElement",
  components: {VuetifyAudio},
  mixins: [
      constantTestBuilder
  ],
  props: {
    optionLayoutProp: {
      type: [Number, String],
      default: "gallery", // this.question_layout_type_constant.GALLERY
    },
    optionContentTypeProp: {
      type: [Number, String],
      default:  1, // this.question_content_type_constant.TEXT
    },
    dataOptionProp: {
      type: Array,
      default: () => [
        {
          uuid: null,
          text: "",
          image_data: null,
          image_file: null,
          audio_data: null,
          audio_file: null,
          offset: 1,
        }
      ],
    },
    correctIndexProp: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    question_content_types() {
      return [
        {label: "Text", value: this.question_content_type_constant.TEXT},
        {label: "Ảnh và mô tả", value: this.question_content_type_constant.TEXT_AND_IMAGE},
        {label: "Audio", value: this.question_content_type_constant.AUDIO},
      ]
    },
    optionLayoutType: {
      get() {
        return this.optionLayoutProp;
      },
      set(value) {
        this.$emit("setOptionLayoutType", value);
      },
    },
    optionContentType: {
      get() {
        return this.optionContentTypeProp;
      },
      set(value) {
        this.$emit("setOptionContentType", value);
      },
    },
    dataOption: {
      get() {
        return this.dataOptionProp;
      },
      set(value) {
        this.$emit("setDataOption", value);
      },
    },
    correctOptionIndex: {
      get() {
        return this.correctIndexProp;
      },
      set(value) {
        this.$emit("setCorrectIndex", value);
      },
    },
  },
  watch: {
    optionContentType(val, oldVal) {
      this.setDefaultData();
      let vm = this;
      if (this.optionLayoutType === this.question_layout_type_constant.GALLERY) {
        if ([
              this.question_content_type_constant.AUDIO,
            ].includes(val))
        {
          this.messError("Không thể chọn option type là audio cho layout gallery");
          vm.$nextTick(function() {
            vm.optionContentType = oldVal;
          });
        }
      }
      if (this.optionLayoutType === this.question_layout_type_constant.LIST) {
        if ([
              this.question_content_type_constant.IMAGE,
              this.question_content_type_constant.TEXT_AND_IMAGE,
            ].includes(val))
        {
          this.messError("Không thể chọn option type là image cho layout list");
          vm.$nextTick(function() {
            vm.optionContentType = oldVal;
          });
        }
      }
    },
    optionLayoutType() {
      this.optionContentType = this.question_content_type_constant.TEXT;
    },
  },
  methods : {
    messError(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    addOption() {
      if (this.dataOption.length >=4 ) {
        this.messError("Chỉ được thêm tối đa 4 option");
        return;
      }
      // get offset lagest
      let offset = 0;
      this.dataOption.forEach((option) => {
        if (option.offset > offset) {
          offset = option.offset;
        }
      });
      this.dataOption.push({
        uuid: null,
        text: "",
        image_data: null,
        image_file: null,
        audio_data: null,
        audio_file: null,
        offset: offset + 1,
      });
    },
    deleteOption(index) {
      if (this.dataOption.length <= 1) {
        this.messError("Phải có tối thiểu 1 option");
        return;
      }
      this.dataOption.splice(index, 1);
    },
    onImageFileOptionChange(file, indexOption) {
      let vm = this;
      if (file === undefined || file == null) {
        vm.dataOption[indexOption].image_data = null;
        vm.dataOption[indexOption].image_file = null;
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        vm.dataOption[indexOption].image_data = e.target.result;
        vm.dataOption[indexOption].image_file = file;
      };
      reader.readAsDataURL(file);
    },
    onAudioFileOptionChange(file, indexOption) {
      let vm = this;
      if (file === undefined || file == null) {
        vm.dataOption[indexOption].audio_data = null;
        vm.dataOption[indexOption].audio_file = null;
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        vm.dataOption[indexOption].audio_data = e.target.result;
        vm.dataOption[indexOption].audio_file = file;
      };
      reader.readAsDataURL(file);
    },
    setDefaultData() {
      this.dataOption = [{uuid: null, text: "", image_data: null, image_file: null, audio_data: null, audio_file: null}];
      this.correctOptionIndex = null;
    },
  },
}
</script>

<style scoped>
.label_default {
  font-size: 16px;
}
</style>